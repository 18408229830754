@import 'static/webfonts/all.css';

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'),
    url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat-Light'),
    url('./fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'),
    url('./fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}

html {
  min-height: 100%;
  margin: 0;
}

body,
.modal-body {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: white;
  margin: 0;
  height: 100%;
  background-color: #fff !important;
}

.h1 {
  margin-top: 80px;
  color: #000;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

h2 {
  font-size: 35px;
  font-family: 'Montserrat-Light';

  @media screen and (min-width: 600px) {
    font-size: 42px;
  }
}

.alt-h2 {
  font-size: 24px;
  font-family: 'Montserrat-Regular';
  font-weight: lighter;
  text-transform: uppercase;
}

h3 {
  font-size: 22px;
  color: #00c1ff;
  font-family: 'Montserrat-Bold';
  text-transform: uppercase;
}

.light-h3 {
  font-family: 'Montserrat-Regular';
}

.alt-h3 {
  color: #fff;
  font-family: 'Montserrat-Regular';
}

strong {
  font-family: 'Montserrat-Bold';
}

h6 {
  font-size: 24px;
}

h4 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 16px;
}

.standalone-h4 {
  color: #000;
  margin-top: 2.5rem;
  text-transform: uppercase;
}

p {
  font-size: 16px;
}

ul {
  padding-left: 20px;
}

.title-light {
  color: #fff;
}

.tj-main {
  flex: 1;
  flex-direction: column;
  padding: 0 10%;
  @media screen and (min-width: 360px) {
    padding: 5rem 10%;
  }
  @media screen and (min-width: 1920px) {
    padding: 8rem 10%;
  }
}

.container {
  margin: 0 auto;
  padding-bottom: 30px;

  .selection-cards {
    display: flex;
    margin-top: 15px;
  }
}

.tj-grid-container {
  padding: 3.5rem 10% 5rem;
  width: 100%;
  @media screen and (min-width: 360px) {
    padding: 3.5rem 7% 5rem;
  }
  @media screen and (min-width: 1920px) {
    padding: 3.5rem 10% 5rem;
  }
}

.form {
  max-width: 800px;
  margin: 0 auto;
}

.p {
  color: #bf1650;
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;

  &:visited,
  &:focus,
  &:active {
    color: inherit;
  }
}

.body-link {
  color: #034ea2;
  text-decoration: underline;

  &:visited,
  &:focus,
  &:active {
    color: #034ea2;
  }
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #333;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.label,
section > label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

.input-style {
  background: #fff;
  padding: 5px 10px;
  margin-bottom: 10px;
  border: 0.5px solid #64646e;
  border-radius: 26px;
}

.MuiInput-underline::after {
  transition: transform 200ms cubic-bezier(1, 1, 0.2, 1) 0ms;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-bottom: none !important;
  pointer-events: none;
  height: 41px;

  width: 100%;
  border-radius: 26px;
}

:focus {
  outline: none;
}

.MuiInput-underline::before {
  display: none;
}

input[type='submit'] {
  background: #0098d8;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 100%;

  &.grey-bg {
    background: grey;
  }

  &:hover {
    cursor: pointer;
    background: #01c1fe;
  }
}

.buttonBlack {
  background: black;
  border: 1px solid white;
}

.form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  max-width: 800px;
  margin: 5rem auto;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.tab-container {
  max-width: 600px;
  margin: 0 auto;
}

.pagination-container {
  display: flex;
  justify-content: space-between;

  button {
    margin-top: 20px;
  }
}

form {
  width: 100%;
  padding: 30px;
}

// Togggle Buttons
.application-toggle-container {
  margin-bottom: 22px;
}

// Custom create button
.create-button-container {
  display: flex;
  margin: 2rem 0 0;
  justify-content: flex-end;

  .create-button {
    // background: #fff !important;
    display: flex;

    .button-right-icon {
      margin-left: 10px;
      margin-top: -3px;
    }
  }
}

// Error Messages
.error-msg {
  color: red;
}

.loading-error-container {
  margin-top: 1rem;

  p {
    color: red;

    span {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.create-button-contianer {
  margin: 1rem;
  display: flex;
  font-size: 16px;
  justify-content: flex-end;

  div:hover {
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
  }
}

.MuiToolbar-root .MuiButtonBase-root:hover {
  background-color: transparent !important;
}

// Buttons
.swal-button--confirm {
  background-color: #0098d8;
  border: 1px solid #0098d8;
}

// Alert Modal
.swal2-container.swal2-center > .swal2-popup {
  border-radius: 32px;
  width: 500px;
}

#swal2-title {
  font-size: 24px;
  color: #fff;
  font-family: 'Montserrat-Regular';
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  padding: 0.6rem;
  background: transparent linear-gradient(90deg, #00c1ff 0%, #08f9ba 100%) 0% 0%
    no-repeat padding-box;
}

.swal2-styled.swal2-cancel {
  background: #fff !important;
}

.confirm-button {
  width: 155px;
  height: 50px;
  border-radius: 26px !important;
  text-align: center;
  background: transparent linear-gradient(90deg, #00c1ff 0%, #08f9ba 100%) 0% 0%
    no-repeat padding-box !important;

  &:hover {
    box-shadow: 0px 3px 6px #00000029 !important;
  }
}

.cancel-button {
  width: 155px;
  height: 50px;
  border-radius: 26px !important;
  text-align: center;
  color: #c8c8d2 !important;
  border: 1px solid #c8c8d2 !important;

  &:hover {
    box-shadow: 0px 3px 6px #00000029;
  }

  &:focus {
    box-shadow: none !important;
  }
}

// Table styles
.base-table {
  tbody tr:nth-child(2n + 1),
  tfoot tr:nth-child(2n + 1) {
    background-color: #f9f9fe;
  }
  tbody tr:nth-child(2n),
  tfoot tr:nth-child(2n) {
    background-color: #eaeaf1;
  }
}

td.MuiTableCell-root {
  border-bottom: none;
}

th span {
  font-weight: 600;
  text-transform: uppercase;
}

tr:first-child {
  background-color: transparent;
}

tbody,
tfoot {
  tr:nth-child(odd) {
    background-color: #c8c8d233;
  }
}

td.MuiTableCell-root {
  border-bottom: none;
}

th span {
  font-weight: 600;
  text-transform: uppercase;
}

tr:first-child {
  background-color: transparent;
}

tbody,
tfoot {
  tr:nth-child(odd) {
    background-color: #ffffff33;
  }
}

.enabled-container {
  display: flex;
  align-items: center;

  span {
    margin: 0 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #fff;
    color: #c8c8d2;
    border: 1px solid #c8c8d2;

    &.confirmed {
      background: #08f9ba;
      border-color: #08f9ba;

      &.t {
        background: #ee236b;
        border-color: #ee236b;
      }

      &.s {
        background: #00c1ff;
        border-color: #00c1ff;
      }

      &.i {
        background: #08f9ba;
        border-color: #08f9ba;
      }
    }
  }
}

.form-button-container {
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;

  div:hover {
    cursor: pointer;
    box-shadow: 0px 3px 6px #00000029;
  }
}

.swal2-actions {
  button:hover {
    box-shadow: 0px 3px 6px #00000029;
  }
}

.tj-button {
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029 !important;
}

//** Table Styles

// Sort
.MuiTableSortLabel-root:hover,
.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: #08f9ba !important;
  opacity: 1;
}
.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon {
  fill: #08f9ba !important;
  opacity: 1;
}

// Pagination
.MuiTablePagination-toolbar {
  div,
  .MuiSelect-icon,
  span {
    color: #fff;
  }
}

// Tabs
.MuiTabs-flexContainer {
  flex-wrap: wrap;
}

// TODO Overwrite within component state
.MuiAccordion-root {
  background: transparent !important;
}

//  SWAGGER Overrides
.renderedMarkdown {
  p {
    text-transform: none;
  }
}

// TODO Ovverride properly without important - Testing styles for PR - CIEI-8
.swagger-ui {
  * {
    font-family: 'Montserrat-Regular' !important;
  }
  .info .title {
    font-family: 'Montserrat-Regular' !important;
  }

  .markdown p {
    text-transform: none;
  }

  .scheme-container {
    box-shadow: none !important;
  }

  .opblock.opblock-post {
    background: #08f9ba1a !important;
    border: none;
    .opblock-summary-method {
      border-radius: 26px !important;
      background: #08f9ba !important;
    }
  }

  .opblock-summary-control:focus {
    outline: none !important;
  }

  // Table
  .col_header {
    padding: 12px 20px;
  }
  table tbody tr td:first-of-type,
  table tbody tr td {
    padding: 12px 20px !important;
  }

  // Buttons
  .btn-group {
    .btn:first-child {
      margin-right: 20px;
    }
  }
  .opblock-summary- .btn-group .btn:first-child,
  .btn.try-out__btn,
  .btn.execute.opblock-control__btn {
    display: flex;
    border-radius: 26px !important;
    font-size: 16px;
    color: #fff;
    background: transparent linear-gradient(90deg, #00c1ff 0%, #08f9ba 100%) 0%
      0% no-repeat padding-box;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border: none;
  }

  .btn-group .btn:last-child,
  .btn.try-out__btn.cancel {
    display: flex;
    border-radius: 26px !important;
    font-size: 16px;
    color: #c8c8d2;
    border: 1px solid #c8c8d2;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background: transparent;
  }
}

.hr-container {
  text-align: left;
  width: 120px;

  .small-hr {
    text-align: left;
    margin-top: 50px;
    color: #00000039;

    &.light {
      color: #fff;
    }
  }
}

.code-box-container {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #00000029;

  .header-container {
    background: #034ea2;
    color: #00c1ff;
    display: flex;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 1rem 3rem;

    div {
      width: 50%;
      text-align: left;
      font-size: 20px;
      font-weight: 600;
      font-family: 'Montserrat-Bold';
    }
  }

  .header-container2 {
    background: #034ea2;
    color: #fff;
    display: flex;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 1rem 3rem;

    div {
      width: 50%;
      text-align: left;
      font-size: 20px;
      font-weight: 600;
      font-family: 'Montserrat-Bold';
    }
  }

  .body-container {
    display: flex;
    padding: 3rem;
    div {
      width: 80%;
      font-size: 14px;
      text-align: left;
    }
  }
}

// Rotate Device
.hide-mobile {
  display: block;
  height: 100%;
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}

.iconContainer {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  margin-top: -100px;

  & p {
    margin: 0;
    opacity: 0;
    width: 100%;
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: inline-block;
  }
}

.phone {
  position: relative;
  left: 50%;
  margin-left: -50px;
  width: 0;
  height: 0;
  border: 0 solid #000;
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  & i {
    text-align: center;
    width: 100%;
    line-height: 120px;
    font-size: 50px;
    color: #454545;
    opacity: 0;
  }
  & img {
    text-align: center;
    width: 85%;
    margin: 0 auto;
    line-height: 120px;
    font-size: 50px;
    color: #454545;

    margin: 0 auto;
    margin-left: auto;
    width: 75%;
    height: 100%;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .overlay {
    display: block;
  }

  .hide-mobile {
    display: none;
  }

  .overlay .phone {
    animation: sizeIncrease 0.5s forwards ease,
      borderIncrease 0.5s 0.5s forwards ease, rotateRight 0.7s 1s forwards ease;
  }

  .overlay .phone i {
    animation: fadeIn 0.5s 0.8s forwards ease;
  }

  .overlay .phone img {
    animation: rotateLeft 0.5s 0.8s forwards ease;
  }

  .overlay .iconContainer p {
    animation: fadeIn 0.5s 1.3s forwards ease;
  }
}

/* KEYFRAMES
------------------------------------------- */
/* Animate width + height */
@keyframes sizeIncrease {
  0% {
    width: 0;
    height: 10px;
  }
  50% {
    width: 100px;
    height: 10px;
  }
  100% {
    width: 100px;
    height: 160px;
  }
}
/* Add borders */
@keyframes borderIncrease {
  100% {
    border-width: 20px 10px;
  }
}

/* fade-in  */
@keyframes fadeIn {
  100% {
    color: #fff;
    opacity: 1;
  }
}

/* Rotate device */
@keyframes rotateRight {
  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotateLeft {
  100% {
    transform: rotate(-90deg);
  }
}

// Remove width param on Schema

.swagger-ui table.model tbody tr td:first-of-type {
  width: 15% !important;
}

.swagger-ui table tbody tr td:first-of-type {
  min-width: 5rem !important;
}

.swagger-ui table tbody tr td:first-of-type,
.swagger-ui table tbody tr td {
  padding: 10px 5px !important;
}

.link {
  font: 16px Montserrat-Regular;
  text-decoration-line: underline;
}

.link:hover {
  color: #034ea2;
}

//to make the YAML text container transparent
.scheme-container {
  background-color: transparent !important;
}

//sidenav
.sidebarBody {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto;
  z-index: 1;
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #00c1ff;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
